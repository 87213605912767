.listContainer {
	height: calc(100% - 65px);
	width: 100%;
	position: absolute;
	overflow-y: scroll;
	padding-right: 20px;
}

#detail {
	height: calc(100% - 65px);
	position: fixed;
	top: 65px;
	overflow-y: auto;
	right: 0;
}

.modal-90w {
	max-width: 85% !important;
}
