:root {
  --accent: #D4E056;
  --primary: #505160;
  --primary-dark: #32323F;
  --destructive: #D32F2F;
}


/* Primary */

.btn-primary {
	color: var(--primary);
	background-color: var(--accent);
	border-color: var(--accent);
}

.btn-primary:hover {
	color: var(--primary);
	background-color: var(--accent);
	border-color: var(--accent);
}


/* Secondary */

.btn-secondary {
	color: #FFFFFF;
	background-color: var(--primary);
	border-color: var(--primary);
}

.btn-secondary:hover {
	color: #FFFFFF;
	background-color: var(--primary);
	border-color: var(--primary);
}

.btn-outline-secondary {
	color: var(--primary);
	background-color: #FFFFFF;
	border-color: var(--primary);
}

.btn-outline-secondary:hover {
	color: #FFFFFF;
	background-color: var(--primary);
	border-color: var(--primary);
}

/* Destructive */

.btn-destructive {
	color: #FFFFFF;
	background-color: var(--destructive);
	border-color: var(--destructive);	
}

.btn-destructive:hover {
	color: #FFFFFF;
	background-color: var(--destructive);
	border-color: var(--destructive);	
}

.btn-outline-destructive {
	color: var(--destructive);
	background-color: #FFFFFF;
	border-color: var(--destructive);	
}

.btn-outline-destructive:hover {
	color: #FFFFFF;
	background-color: var(--destructive);
	border-color: var(--destructive);	
}


/* Text */
.modal-header {
	font-weight: 600;
	font-size: 24px;
	color: var(--primary-dark);
}

.escandall-header {
	font-weight: 600;
	font-size: 24px;
	color: var(--accent);
}

.property-header {
	font-weight: 700;
	font-size: 16px;
	color: var(--primary-dark);
	line-height: 1.5;
	margin-bottom: 0px;
	text-align: left;
}

.property-content {
	font-weight: 400;
	font-size: 16px;
	color: var(--primary);
	line-height: 1.5;
	text-align: left;
}

.property-form {
	margin-bottom: 20px;
}

.section-header {
	font-weight: 800;
	font-size: 20px;
	color: var(--primary-dark);
	margin-top: 30px;
}

a:link {
	color: var(--primary-dark);
}

a:visited {
	color: var(--primary-dark);
}

a:hover {
	color: var(--accent);
}

a:active {
	color: var(--primary-dark);
}


/* Miscelaneous */
.list-group-item.active {
	background-color: var(--primary);
	border-color: var(--primary);
	color: #FFFFFF;
}