.navBar {
	background-color: var(--primary);
	height: 65px;
	margin: 0;
}

.brandCell {
	background-color: var(--primary-dark);
	color: white;
	font-size: 24px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.navCell {
	padding: 0;
}

.navCellUnderline {
	display: none;
	background-color: var(--accent);
	width: 100%;
	height: 10px;
	position: absolute;
	bottom: 0;
}

.navCellUnderlineVisible {
	display: block;
	background-color: var(--accent);
	width: 100%;
	height: 10px;
	position: absolute;
	bottom: 0;
}

.navCellButton:hover + .navCellUnderline {
	display: block;
}

.navCellButton {
	display: inline-block;
	height: 100%;
	width: 100%;
	color: white;
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	text-decoration: none !important;
	padding: 0 40px 0 40px;
}

.navCellButtonVisible {
	display: inline-block;
	height: 100%;
	width: 100%;
	color: var(--accent);
	text-align: center;
	font-weight: 600;
	font-size: 18px;
	text-decoration: none !important;
	padding: 0 40px 0 40px;
}

.navCellButton:hover, .navCellButtonVisible:hover {
	color: var(--accent);
}

.navDropdown:hover {
	background-color: var(--primary-dark);
}

.navDropLabel {
	margin: 0;
	padding: 0;
	text-align: right;
}

.restaurantLabel {
	font-weight: 800;
	color: white;
}

.userLabel {
	color: white;
}

.dropdown-toggle:after {
    color: white;
}
